@import '~bootstrap/scss/bootstrap';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.power-hour {
  background-color: #ffffff;
  height: 100vh;
  text-align: center;
  transition: background-color .1s ease-in-out;

  &.drinking{
    background-color: #28a745;
    color: #ffffff;
  }
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 20px;
  transform: translate(-50%, -50%);
}

.count-down {
  font-size: 6rem;
}

.drink-warn {
  font-size: 8rem;
}

.start-btn {
  min-width: 200px;
}
